import './Faq.css'
import { faq } from '../../../content'
import Box from "@mui/material/Box";
import ItemCollapse from "../../../components/ItemCollapse/ItemCollapse";
import uniqid from "uniqid";
import InDev from "../InDev/InDev";

const Faq = () => {
    return (
        <>
            <section className='section faq center' id='faq'>
                {/*<h2 className='faq__title'>
                    Frequently asked questions
                </h2>
                <p className='faq__desc'>Find answers to commonly asked questions about Investeom and our services.</p>
                <Box p={5} sx={{ width: '100%' }}>
                    <>
                        {faq.map((faq) => (
                            <ItemCollapse key={uniqid()} faq={faq} />
                        ))}
                    </>
                </Box>

                <div className='faq__contact center'>
                    <a href={'#contact'}>
                    <span type='button' className='btn btn--outline'>
                        Contact us
                    </span>
                    </a>
                </div>
                */}
                <h2 className='section__title'>Still have questions?</h2>
                <p className='faq__desc'>Contact us today for more information and start investing in Neom with Investeom.</p>
                <hr/>
            </section>
        </>
    )
}

export default Faq