import { header } from '../../../content'
import Navbar from '../../../components/Navbar/Navbar'
import './Header.css'
const Header = () => {
    const { homepage, title } = header

    return (
        <header className='header center'>
            <h3>
                {homepage ? (
                    <a href={homepage} className='link'>
                        {title}
                    </a>
                ) : (
                    title
                )}
                <svg className="flag" width="1" height="1">
                    <rect width="100%" height="100%" fill="#FF3C38"/>
                    <g fill="#FFF">
                        <rect x="40%" y="16.6666%" width="20%" height="66.6666%"/>
                        <rect x="16.6666%" y="40%" width="66.6666%" height="20%"/>
                    </g>
                </svg>
            </h3>
            <Navbar />
        </header>
    )
}

export default Header
