import "./Success.css";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import Grid from "@mui/material/Grid";
import {Stack, useMediaQuery} from "@mui/material";
import React from "react";
const Success = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <section  className='section success center' id='sucess'>
                <div className='title'>
                    <p className='success__title'>
                        Customer Stories
                    </p>
                </div>
                <Grid container padding={6} spacing={2} columns={matches ? (2) : (1)}>
                    <Grid item xs={1}>
                        <h1>
                            Quick <br />
                            Overview<br />
                        </h1>
                        <p className='success__desc'>Save Time with Proven Results.</p>
                        <br />
                        <Stack direction="row" spacing={1}>
                            <h4 className='success__name'>Quick returns in less than</h4>
                            <h2><span className='success__nb'><CountUp start={0} end={1} duration={4} /> year</span></h2>
                        </Stack>
                        <br />
                        <Stack direction="row" spacing={1}>
                            <h2>
                                <span className='success__nb'> <CountUp start={0} end={30} duration={4} />% </span>
                            </h2>
                            <h4 className='success__name'>to</h4>
                            <h2>
                                <span className='success__nb'> <CountUp start={0} end={50} duration={4} />% </span>
                            </h2>
                            <h4 className='success__name'>return on investment</h4>
                        </Stack>
                        <br />
                        <Stack direction="row" spacing={1}>
                                <h4 className='success__name'>Invest</h4>
                                <h2>
                                    <span className='success__nb'><CountUp start={500} end={1000} duration={4} />€ </span>
                                </h2>
                            <h4 className='success__name'>to several million</h4>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                        <motion.div
                                initial={{ x: "7rem", opacity: 0 }}
                                animate={{ x: "0", opacity: 1 }}
                                transition={{
                                    duration: 2,
                                    type: "spring"
                                }}
                                className="image-container"
                            >
                                <img src={"https://i.imgur.com/Ak4pzfq.jpg"} alt="success" />
                        </motion.div>
                    </Grid>
                </Grid>
        </section>
    );
};
export default Success
