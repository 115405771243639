const header = {
    homepage: '#',
    title: 'Investeom',
}

const about = {
    name: 'Investeom',
    role: 'Allows Swiss and European investors to invest in NEOM\'s best real estate projects',
    description:
        'Get high returns on investment',
    text:'What is happening in Saudi Arabia and in particular the Neom project is exceptional. In addition, the construction is guaranteed by the sovereign fund which already has the liquidity to complete all the construction started. As there is no significant taxation we suggest you invest your money in the best real estate opportunities and earn profits thanks to your capital.',
    resume: 'mailto: ',
    social: {
        linkedin: 'https://linkedin.com',
    },
}
const event = {
    name: 'Find Your Next Investment Opportunity',
    description:
        'Discover our top featured properties and learn more about the unique opportunities they offer for investors.',
    resume: '#',
}

const projects = [
    {
        name: 'Browse Available Options',
        description:
            'Explore our wide selection of properties, businesses, and real estate projects to find the perfect investment opportunity for you.',
        livePreview: '#',
    },
    {
        name: 'Connect with Our Team',
        description:
            'Our team of experts will work closely with you to understand your investment goals and guide you towards the best options.',
        livePreview: '#',
    },
    {
        name: 'Start Building Your Future',
        description:
            'Invest in your future today and watch your financial portfolio grow with our top-notch investment opportunities.',
        livePreview: '#',
    },
]

const faq = [
    {
        label: `What is Investeom?`,
        description: `You can invite up to 2 additional users on the Free plan. There is no limit on team members for the Premium plan.`
    },

    {
        label: `What is the maximum file upload size?`,
        description: `No more than 2GB. All files in your account must fit your allotted storage space.`
    },

    {
        label: `How do I reset my password?`,
        description: `Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you.`
    },

    {
        label: `Can I cancel my subscription?`,
        description: `Yes! Send us a message and we’ll process your request no questions asked.`
    },

    {
        label: `Do you provide additional support?`,
        description: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`
    }
];

const contact = {
    email: 'johnsmith@mail.com',
}

const team= [
    {
        "img": "img/team/01.jpg",
        "name": "John Doe",
        "job": "Director"
    },
    {
        "img": "img/team/02.jpg",
        "name": "Mike Doe",
        "job": "Senior Designer"
    },
    {
        "img": "img/team/03.jpg",
        "name": "Jane Doe",
        "job": "Senior Designer"
    },
    {
        "img": "img/team/04.jpg",
        "name": "Karen Doe",
        "job": "Project Manager"
    }
];

const data = [
    {
        description : "Save Time with Proven Results."
    }
]

const inDev = {
    name: 'Investeom is a platform currently under development',
    text1:
        'We will soon register a company based in Geneva which will be able to collect money from investment funds, family offices and private investors in order to invest it in NEOM\'s real estate and commercial projects in Saudi Arabia.',
    text2:
        'We will work to obtain authorization from the Swiss Financial Market Supervisory Authority FINMA.',
    text3:
        'You are a professional wishing to join us in this fabulous project, you are welcome.',
    resume: '#',
}


export { header, about, projects, event, contact ,faq ,team ,data, inDev}
