import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { about } from '../../../content'
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Grid';
import './About.css';
import {useMediaQuery} from "@mui/material";


const itemData = [
    {
        img: 'https://i.imgur.com/ybGtnjq.png',
    },
    {
        img: 'https://i.imgur.com/Y8fIepD.png',
    },
    {
        img: 'https://i.imgur.com/eQ84yqs.png',
    },
    {
        img: 'https://i.imgur.com/2Tfo0qp.png',
    },
    {
        img: 'https://i.imgur.com/incQfTr.png',
    },
    {
        img: 'https://i.imgur.com/lol3o2C.png',
    },
];

const About = () => {
    const { name, role, description, text } = about
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <>
            <Grid container spacing={2} columns={16}>
                <Grid item xs={8}>
                    <div className='about'>
                        <h1>
                            Welcome to <span className='about__name'>{name}.</span>
                        </h1>
                        <h2 className='about__role'>{role}</h2>
                        <p className='about__desc'>{description && description}</p>
                        <p className='about__desc'>{text}</p>
                        <div className='about__contact center'>
                            <a href={"mailto:contact@swisseva.com, matthieu.seigneur.pro@gmail.com"}>
                                <span type='button' className='btn btn--outline'>
                                    Join Now
                                </span>
                            </a>
                            {/*
                            <>
                                <a
                                    href={social.linkedin}
                                    aria-label='linkedin'
                                    className='link link--icon'
                                >
                                    <LinkedInIcon />
                                </a>
                            </>
                            */}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{ overflowY: 'hidden'}}>
                        <ImageList variant="woven" cols={matches ? (3) : (1)} gap={matches ? (18) : (0)}>
                            {itemData.slice(0, matches ? itemData.length : 3).map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        alt={item.img}
                                        srcSet={`${item.img}`}
                                        src={`${item.img}`}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </Grid>
            </Grid>
    </>

    )
}

export default About
