import {event} from '../../../content'
import React from 'react'
import './Event.css'
const Event = () => {
    const {name, description, resume } = event

    return (
        <section className='section' id='event'>
            <div className='event center'>
                <div className='title'>
                    <p className='event__title'>
                        Coming Event
                    </p>
                </div>
                {name && <h2 className='event__role'>{name}.</h2>}
                <p className='event__desc'>{description && description}</p>
                <div className='event__contact'>
                    <a href={resume}>
                        <span type='button' className='btn btn--outline'>
                          Learn More
                        </span>
                    </a>
                </div>
                <div className="flex fill center">
                </div>
            </div>
        </section>
    )
}

export default Event
