import { contact } from '../../../content'
import './Contact.css'

const Contact = () => {
    if (!contact.email) return null

    return (
        <section className='section contact center' id='contact'>

        </section>
    )
}

export default Contact