import './Team.css'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import TeamCard from "../../../components/TeamCard/TeamCard"

const items = [
    <TeamCard
        testimony="Visionnaire & Expert en création de projets leader."
        image="https://i.imgur.com/CmxFK57.png"
        name="Vedat Gedik"
        work="Co-Founder"
    />,
    <TeamCard
        testimony="Expert en IA, développeur informatique & design addict."
        image="https://i.imgur.com/gRSRRoZ.png"
        name="Matthieu Seigneur"
        work="Co-Founder"
    />,
    <TeamCard
        testimony="Expert en gestion, juridique, partenariats & levée de fonds."
        image="https://i.imgur.com/UgoF7bo.png"
        name="Michael GEBREZIABIHER"
        work="Co-foundateur"
    />
];

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1000: { items: 2 }
};
const Team = () => {

    return (
        <div id='team'>
            <section className='section team center' >
                <h2 className='section__title'>Team</h2>
            </section>
            <div className="testimonial-container">
                <AliceCarousel
                    mouseTracking
                    items={items}
                    responsive={responsive}
                    autoPlay
                    infinite
                    autoPlayInterval={2000}
                    animationDuration={2000}
                />
            </div>
        </div>

    )
}

export default Team