import uniqid from 'uniqid'
import { projects } from '../../../content'
import ProjectContainer from '../../../components/ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = () => {
    return (
            <section id='projects' className='section projects'>
                <h2 className='section__title'>How It Works</h2>
                <div className='projects__grid'>
                    {projects.map((project) => (
                        <ProjectContainer key={uniqid()} project={project} />
                    ))}
                </div>
            </section>
    )
}

export default Projects
