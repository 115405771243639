import {inDev} from '../../../content'
import React from 'react'
import './InDev.css'
const InDev = () => {
    const {name, text1,text2,text3, resume } = inDev

    return (
        <section className='inDev' id='event'>
            <div className='inDev center'>
                <h2 className='inDev__role'>{name}.</h2>
                <p className='inDev__desc'>{text1}</p>
                <p className='inDev__desc'>{text2}</p>
                <p className='inDev__desc'>{text3}</p>
                <div className='inDev__contact'>
                    <a href={"mailto:contact@swisseva.com,matthieu.seigneur.pro@gmail.com"}>
                        <span type='button' className='btn btn--outline'>
                           Contact us
                        </span>
                    </a>
                </div>
                <div className="flex fill center">
                </div>
            </div>
        </section>
    )
}

export default InDev
